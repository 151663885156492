@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400; /* Peso normal */
  font-style: normal; /* Estilo normal */
}

@font-face {
  font-family: "Nunito-bold";
  src: url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: 700; /* Peso bold */
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}
